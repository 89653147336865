import {
  Grid,
  FormInput,
  Accordion,
  AccordionPanel,
  Table,
  TableRow,
  TableCell,
  InfoTooltip,
  Dropdown,
} from '@aurecon-creative-technologies/styleguide'
import { useCallback, useEffect, useState } from 'react'
import { BessInputsInterface, CommercialParametersInterface, ScenarioFormData } from 'src/models/ScenarioFormData'
import Style from '../../styles/CreateScenario.module.sass'
import { BessExpectedPerformanceCyclePerDay, BessPowerEfficiency, ProjectInputLgc } from 'src/enums/ScenarioEnums'
import { getCurrentDateISOString } from 'src/helpers/utils'

const bessExpectedPerformanceHeaders = [
  { label: BessExpectedPerformanceCyclePerDay.OPERATING_YEAR },
  { label: BessExpectedPerformanceCyclePerDay.ENERGYDISCHARGECAPACITYRETENTION },
  { label: BessExpectedPerformanceCyclePerDay.RTE },
]

interface Step5Props {
  formData: ScenarioFormData
  setFormData: React.Dispatch<React.SetStateAction<ScenarioFormData>>
  errors: Record<string, string>
}

export const Step5: React.FC<Step5Props> = ({ formData, setFormData, errors }) => {
  const [selected, setSelected] = useState<string[]>(['p1', 'p2', 'p3', 'p4'])

  const togglePanel = (panel: string) => {
    const index = selected.indexOf(panel)
    if (index === -1) {
      setSelected((sel) => [...sel, panel])
      return
    }
    const newSel = [...selected]
    newSel.splice(index, 1)
    setSelected(newSel)
  }

  const handleGeneralInput = (value: string | number | boolean, name: string) => {
    setFormData({
      ...formData,
      [name]: value,
    })
  }

  const handleBessPerformanceData = (id: number, field: string, value: string) => {
    const updatedCollecteData = formData.bess_inputs.map((cap) => {
      if (cap.year === id) {
        return {
          ...cap,
          [field]: value,
        }
      }
      return cap
    })

    setFormData({
      ...formData,
      bess_inputs: updatedCollecteData,
    })
  }

  const addOrRemoveGeneral = useCallback(() => {
    const numberOfObjectsToAdd = Number(formData.project_life_years)
    const currentBessLength = formData.bess_inputs.length

    if (!isNaN(numberOfObjectsToAdd)) {
      const updatedBessCollections = formData.bess_inputs.map((factor, index) => {
        const newYear = Number(formData.first_year_of_commercial_operation) + index
        return {
          ...factor,
          year: newYear,
        }
      })
      for (let i = currentBessLength; i < numberOfObjectsToAdd; i++) {
        const newYear = Number(formData.first_year_of_commercial_operation) + i
        const newBessObj: BessInputsInterface = {
          created_at: getCurrentDateISOString(),
          updated_at: getCurrentDateISOString(),
          created_by: getCurrentDateISOString(),
          updated_by: getCurrentDateISOString(),
          operating_year: i + 1,
          year: newYear,
          energy_discharge_capacity: '0',
          rte: '0',
          correction_factor_half_cycle: '0',
          correction_factor_one_cycle: '0',
          correction_factor_one_and_half_cycle: '0',
          correction_factor_two_cycle: '0',
          transfer_efficieny: '0',
          ac_charging_efficieny: '0',
          dc_charging_efficieny: '0',
          generation_efficieny: '0',
        }
        updatedBessCollections.push(newBessObj)
      }

      const shouldUpdate =
        updatedBessCollections.length !== formData.bess_inputs.length ||
        updatedBessCollections.some((factor, index) => factor.year !== formData.bess_inputs[index]?.year)

      if (shouldUpdate) {
        setFormData((prevData) => ({
          ...prevData,
          bess_inputs: updatedBessCollections.slice(0, numberOfObjectsToAdd),
        }))
      }
    }
  }, [formData.project_life_years, formData.bess_inputs, formData.first_year_of_commercial_operation, setFormData])

  useEffect(() => {
    addOrRemoveGeneral()
  }, [addOrRemoveGeneral])

  const addcommercial = useCallback(() => {
    const numberOfObjectsToAdd = Number(formData.project_life_years)
    const currentCommercialLength = formData.commercial_parameters.length

    if (!isNaN(numberOfObjectsToAdd)) {
      const updatedCommercialCollections = formData.commercial_parameters.map((factor, index) => {
        const newYear = Number(formData.first_year_of_commercial_operation) + index
        return {
          ...factor,
          year: newYear,
        }
      })
      for (let i = currentCommercialLength; i < numberOfObjectsToAdd; i++) {
        const newYear = Number(formData.first_year_of_commercial_operation) + i
        const newCommercialObj: CommercialParametersInterface = {
          created_at: getCurrentDateISOString(),
          updated_at: getCurrentDateISOString(),
          created_by: getCurrentDateISOString(),
          updated_by: getCurrentDateISOString(),
          operating_year: i + 1,
          year: newYear,
          long_term_service_agreement_cost_percentage: '0',
          manual_long_term_service_agreement_cost: '0',
          warranty_cost_percentage: '0',
          manual_warranty_cost_absolute: '0',
          tuos_cost: '0',
          lgc_price: '0',
          lgc_fraction: '0',
          other_o_and_m_cost: '0',
          default_warranted_cycling: '0',
          bess_long_term_service_agreement_cost: 0,
          re_generator_long_term_service_agreement_cost: 0,
        }
        updatedCommercialCollections.push(newCommercialObj)
      }

      const shouldUpdate =
        updatedCommercialCollections.length !== formData.commercial_parameters.length ||
        updatedCommercialCollections.some(
          (factor, index) => factor.year !== formData.commercial_parameters[index]?.year,
        )

      if (shouldUpdate) {
        setFormData((prevData) => ({
          ...prevData,
          commercial_parameters: updatedCommercialCollections.slice(0, numberOfObjectsToAdd),
        }))
      }
    }
  }, [
    formData.project_life_years,
    formData.commercial_parameters,
    formData.first_year_of_commercial_operation,
    setFormData,
  ])

  useEffect(() => {
    addcommercial()
  }, [addcommercial])

  const handleCommercialData = (id: number, field: string, value: string) => {
    const updatedCollecteData = formData.commercial_parameters.map((cap) => {
      if (cap.operating_year === id) {
        return {
          ...cap,
          [field]: value,
        }
      }
      return cap
    })

    setFormData({
      ...formData,
      commercial_parameters: updatedCollecteData,
    })
  }

  return (
    <div className='mainContainerBlock'>
      <h2>General Input</h2>
      <Grid row gap={12}>
        <Grid item>
          <div className={Style.p1Note}>
            Note: Market price elasticity refers to the effect that adding additional bids into energy and FCAS markets
            has on increasing market competition and suppressing prices.
          </div>
        </Grid>
      </Grid>
      <Grid row gap={12}>
        <Grid item lg={6}>
          <p className={Style.labelHead}>
            <b>
              Standby Power Consumption{' '}
              <InfoTooltip show='Auxiliary power usage while at standby (MW per MWh BOL capacity)' />
            </b>
          </p>
          <FormInput
            type='number'
            label={''}
            placeholder={''}
            value={formData.standby_power_consumption.toString()}
            onChange={(v) => {
              handleGeneralInput(v, 'standby_power_consumption')
            }}
            error={errors.standby_power_consumption}
          />
        </Grid>
        <Grid item lg={4} cssClass={Style.alignContent}>
          <Dropdown
            label=''
            items={[
              {
                id: '1',
                label: 'MW / MWh installed capacity',
              },
              {
                id: '2',
                label: 'KW / MWh  installed capacity',
              },
            ]}
            selectedItem={formData.standby_power_consumption_unit}
            onSelectItem={(v) => handleGeneralInput(v.toString(), 'standby_power_consumption_unit')}
          />
        </Grid>
      </Grid>
      <Grid item lg={12}>
        <Table headers={[{ label: ProjectInputLgc.YEAR }, { label: ProjectInputLgc.Defaultwarrantedcycling }]}>
          {formData.commercial_parameters.map((data) => (
            <TableRow key={data.operating_year}>
              <TableCell cellClass={Style.tableDataPadding}>{data.operating_year}</TableCell>
              <TableCell cellClass={Style.tableDataPadding}>
                <FormInput
                  type='text'
                  label={''}
                  placeholder={''}
                  value={data.default_warranted_cycling.toString()}
                  onChange={(v) => handleCommercialData(data.operating_year, 'default_warranted_cycling', v)}
                  required={false}
                  default={!data.tuos_cost}
                  error={errors[`commercial_parameters[${data.operating_year - 1}].default_warranted_cycling`]}
                />
              </TableCell>
            </TableRow>
          ))}
        </Table>
      </Grid>
      <Grid row gap={12}>
        <Grid item lg={12} cssClass={Style.pb0}>
          <h2>BESS Performance</h2>
        </Grid>
      </Grid>
      <Grid row gap={12}>
        <Grid item lg={12}>
          <Accordion onPanelToggle={togglePanel} activePanelIds={selected} verticalPanelGap='20px'>
            <AccordionPanel
              panelId='p1'
              label={
                <div className={Style.p1Main}>
                  <h3 className={Style.p1Head}>BESS Expected Performance at nominated cycling level</h3>
                  <div className={Style.p1Note}>
                    Note: Typical figures shown. Client may nominate alternate data if available.
                  </div>
                </div>
              }
            >
              <Grid row gap={12} cssClass={Style.p10}>
                <Table headers={bessExpectedPerformanceHeaders}>
                  {formData.bess_inputs.map((data) => (
                    <TableRow key={data.year}>
                      <TableCell cellClass={Style.tableDataPadding}>{data.operating_year}</TableCell>
                      <TableCell cellClass={Style.tableDataPadding}>
                        <FormInput
                          type='text'
                          label={''}
                          placeholder={''}
                          value={data.energy_discharge_capacity.toString()}
                          onChange={(v) => handleBessPerformanceData(data.year, 'energy_discharge_capacity', v)}
                          required={false}
                          default={!data.energy_discharge_capacity}
                          error={errors[`bess_inputs[${data.operating_year}].energy_discharge_capacity`]}
                        />
                      </TableCell>
                      <TableCell cellClass={Style.tableDataPadding}>
                        <FormInput
                          type='text'
                          label={''}
                          placeholder={''}
                          value={data.rte.toString()}
                          onChange={(v) => handleBessPerformanceData(data.year, 'rte', v)}
                          required={false}
                          default={!data.rte}
                          error={errors[`bess_inputs[${data.operating_year}].rte`]}
                        />
                      </TableCell>
                    </TableRow>
                  ))}
                </Table>
              </Grid>
            </AccordionPanel>
          </Accordion>
        </Grid>
      </Grid>
      {String(formData.scenario_project_type) === '2' && (
        <>
          <Grid row gap={12}>
            <Grid item lg={12} cssClass={Style.pb0}>
              <h2>Efficiency Parameter</h2>
            </Grid>
          </Grid>
          <Grid row gap={12}>
            <Grid item lg={12}>
              <Accordion onPanelToggle={togglePanel} activePanelIds={selected} verticalPanelGap='20px'>
                <AccordionPanel panelId='p1' label={''}>
                  <Grid row gap={12} cssClass={Style.p10}>
                    <Table
                      headers={[
                        { label: BessExpectedPerformanceCyclePerDay.OPERATING_YEAR },
                        ...(['1', '2'].includes(String(formData.hybrid_generation_type)) &&
                        ['1', '2'].includes(String(formData.coupled_type))
                          ? [
                              { label: BessPowerEfficiency.GENERATION_EFFICIENCY },
                              { label: BessPowerEfficiency.AC_EFFICIENCY },
                            ]
                          : []),
                        ...(['1', '2'].includes(String(formData.hybrid_generation_type)) &&
                        String(formData.coupled_type) === '1'
                          ? [{ label: BessPowerEfficiency.CROSS_TRANSFER_EFFICIENCY }]
                          : [{ label: BessPowerEfficiency.DC_EFFICIENCY }]),
                      ]}
                    >
                      {formData.bess_inputs.map((data) => (
                        <TableRow key={data.year}>
                          <TableCell cellClass={Style.tableDataPadding}>{data.operating_year}</TableCell>
                          {['1', '2'].includes(String(formData.hybrid_generation_type)) &&
                            ['1', '2'].includes(String(formData.coupled_type)) && (
                              <>
                                <TableCell cellClass={Style.tableDataPadding}>
                                  <FormInput
                                    type='text'
                                    label={''}
                                    placeholder={''}
                                    value={data.generation_efficieny.toString()}
                                    onChange={(v) => handleBessPerformanceData(data.year, 'generation_efficieny', v)}
                                    required={false}
                                    default={!data.generation_efficieny}
                                    error={errors[`bess_inputs[${data.operating_year}].generation_efficieny`]}
                                  />
                                </TableCell>
                                <TableCell cellClass={Style.tableDataPadding}>
                                  <FormInput
                                    type='text'
                                    label={''}
                                    placeholder={''}
                                    value={data.ac_charging_efficieny.toString()}
                                    onChange={(v) => handleBessPerformanceData(data.year, 'ac_charging_efficieny', v)}
                                    required={false}
                                    default={!data.ac_charging_efficieny}
                                    error={errors[`bess_inputs[${data.operating_year}].ac_charging_efficieny`]}
                                  />
                                </TableCell>
                              </>
                            )}
                          {['1', '2'].includes(String(formData.hybrid_generation_type)) &&
                          String(formData.coupled_type) === '1' ? (
                            <TableCell cellClass={Style.tableDataPadding}>
                              <FormInput
                                type='text'
                                label=''
                                placeholder=''
                                value={data.transfer_efficieny.toString()}
                                onChange={(v) => handleBessPerformanceData(data.year, 'transfer_efficieny', v)}
                                required={false}
                                default={!data.transfer_efficieny}
                                error={errors[`bess_inputs[${data.operating_year}].transfer_efficieny`]}
                              />
                            </TableCell>
                          ) : (
                            <TableCell cellClass={Style.tableDataPadding}>
                              <FormInput
                                type='text'
                                label=''
                                placeholder=''
                                value={data.dc_charging_efficieny.toString()}
                                onChange={(v) => handleBessPerformanceData(data.year, 'dc_charging_efficieny', v)}
                                required={false}
                                default={!data.dc_charging_efficieny}
                                error={errors[`bess_inputs[${data.operating_year}].dc_charging_efficieny`]}
                              />
                            </TableCell>
                          )}
                        </TableRow>
                      ))}
                    </Table>
                  </Grid>
                </AccordionPanel>
              </Accordion>
            </Grid>
          </Grid>
        </>
      )}
    </div>
  )
}
