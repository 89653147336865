import {
  AdditionalField,
  GenerateReportRequest,
  ReportDetail,
  ReportScenarioMapping,
  ReportSection,
  TechnicalAssumption,
} from './GenerateReportRequestFormData'

export const GenerateReportRequestDefaults: GenerateReportRequest = {
  created_at: new Date().toISOString(),
  updated_at: new Date().toISOString(),
  created_by: '',
  updated_by: '',
  name: '',
  description: '',
  project_id: 0,
  preferred_scenario_id: 0,
  execution_scenario_association_id: 0,
  npv_discount_rate: 0,
  report_file_path: '',
  preferred_date: '',
  initialIntroComment: '',
  preferredScenarioIntroComment: '',
  report_detail: ReportDetailDefaults(),
}

function ReportDetailDefaults(): ReportDetail {
  return {
    created_at: new Date().toISOString(),
    updated_at: new Date().toISOString(),
    created_by: '',
    updated_by: '',
    commercial_operation_date: '2024-07-10',
    commercial_operation_comments: '',
    economic_life: 0,
    economic_life_comments: '',
    nem_region: '',
    contingency_fcas_registered_capacity: '0',
    regulation_fcas_registered_capacity: '0',
    cap_contract_price: '0',
    cap_contract_volume: '0',
    generation_marginal_loss_factor: '0',
    load_marginal_loss_factor: '0',
    pop_wholesale_correction_factor: '0',
    pop_regulation_fcas_correction_factor: '0',
    pop_contingency_fcas_correction_factor: '0',
    capital_cost: '0',
    average_total_o_and_m_cost: '0',
    lgc_price: '0',
    outage_rate: '0',
    average_permissable_cycle: '0',
    standby_power_consumption: '0',
    report_scenario_mapping: [ReportScenarioMappingDefaults()],
    technical_assumptions: [TechnicalAssumptionDefaults()],
    report_section: Array.from({ length: 13 }, (_, i) => ReportSectionDefaults(i + 1)),
  }
}

function ReportScenarioMappingDefaults(): ReportScenarioMapping {
  return {
    created_at: new Date().toISOString(),
    updated_at: new Date().toISOString(),
    created_by: '',
    updated_by: '',
    scenario_id: 1,
    discharge_power: '0',
    charge_power: '0',
    bol_storage_capacity: '0',
    cap_contract: '0',
    network_support_service: '0',
    total_net_revenue: '0',
    net_present_value: '0',
    internal_rate_of_return: '0',
    is_non_market_service: '0',
    npv_discount_rate: 0,
    inertia_contract: '0',
    sips_contract: '0',
    vcas_contract: '0',
    sras_contract: '0',
    additional_fields: [AdditionalFieldDefaults()],
  }
}

function TechnicalAssumptionDefaults(): TechnicalAssumption {
  return {
    created_at: new Date().toISOString(),
    updated_at: new Date().toISOString(),
    created_by: '',
    updated_by: '',
    year: 0,
    operating_year: 0,
    energy_storage_retention: '0',
    round_trip_efficiency: '0',
    re_generation: '0',
  }
}

function ReportSectionDefaults(section_id: number): ReportSection {
  return {
    created_at: new Date().toISOString(),
    updated_at: new Date().toISOString(),
    created_by: '',
    updated_by: '',
    general_comment: '',
    formatted_comment: '',
    chart_data: '',
    section_id,
  }
}

function AdditionalFieldDefaults(): AdditionalField {
  return {
    created_at: new Date().toISOString(),
    updated_at: new Date().toISOString(),
    created_by: '',
    updated_by: '',
    name: 'other',
    value: '0',
    slug_name: '',
  }
}
