import { atom, selector } from 'recoil'
import { fetchExecutionsList } from 'src/api/ExecutionsService'
import {
  UploadForecastRequestModel,
  fetchForecastingData,
  fetchForecastingDataForFilter,
  UploadGeneralProfileFormStateRequestModel,
} from 'src/api/ForecastService'
import { FetchReportMappingDataRequestModel, fetchReportsList } from 'src/api/ReportService'
import { fetchAllScenarioData, fetchScenarioData } from 'src/api/ScenarioService'
import { fetchallForecastDetails, fetchallForecastWithFileTypeDetails } from 'src/api/ScenarioServices'
import { ScenarioErrorMessageConfig } from 'src/enums/ScenarioEnums'
import { ScenarioDefaultValues } from 'src/models/ScenarioDefaultValues'
import { ErrorMessageConfig, ScenarioFormData } from 'src/models/ScenarioFormData'
import { CreateProjectRequestModel } from 'src/models/api/CreateProjectRequestModel'
import ExecutionModel from 'src/models/api/ExecutionModel'
import { ExecutionResponse } from 'src/models/api/ExecutionReponseModel'
import ReportsModel from 'src/models/api/ReportsModel'
import { ReportsResponse } from 'src/models/api/ReportsResponseModel'
import {
  fetchAllProjectData,
  fetchAllUser,
  fetchProductedSecret,
  fetchProjectData,
  fetchSwaggerDoc,
  getAppFlags,
  getAppVersion,
} from '../api/AppService'
import { getSecret } from '../api/ProtectedService'
import { getallExecutionData, getdashboardkpi, getEnergy, getnpv, getOverallSummary } from 'src/api/DashboardServices'
import { ProductionSecretModel } from 'src/models/api/ProductionSecret'

export const AppVersion = selector({
  key: 'version',
  get: async () => {
    const response = await getAppVersion()
    return response.data?.version
  },
})

export const AppFlags = selector({
  key: 'app_flags',
  get: async () => {
    const response = await getAppFlags()
    return response.data || {}
  },
})

export const Secret = selector({
  key: 'secret',
  get: async () => {
    const response = await getSecret()
    return response || {}
  },
})

export const TermsModal = atom({
  key: 'terms_modal',
  default: false,
})

export const NotFound = atom<boolean>({
  key: 'not_found',
  default: false,
})

export const projectDataState = atom({
  key: 'projectDataState',
  default: [],
})

export const prjectFormState = atom<CreateProjectRequestModel>({
  key: 'formState',
  default: {
    projectName: '',
    projectNumber: '',
    clientName: '',
    project_type: '1',
    is_confidential: false,
    projectDesc: '',
  },
})

export const currentPageState = atom<number>({
  key: 'currentPageState',
  default: 1,
})

export const forecastcurrentPageState = atom<number>({
  key: 'forecastcurrentPageState',
  default: 1,
})

export const pageSizeState = atom<number>({
  key: 'pageSizeState',
  default: 16,
})

export const navbarCurrentPageState = atom<number>({
  key: 'navbarCurrentPageState',
  default: 1,
})

export const navbarPageSizeState = atom<number>({
  key: 'navbarPageSizeState',
  default: 50,
})

export const projectDataSelector = selector({
  key: 'projectDataSelector',
  get: async ({ get }) => {
    const currentPage = get(currentPageState)
    const pageSize = get(pageSizeState)
    const searchquery = get(searchQueryState)
    const sortColumn = get(projectSortColumnState)
    const sortOrder = get(projectSortOrderState)
    try {
      const projectData = await fetchProjectData(currentPage, pageSize, sortColumn, sortOrder, searchquery)
      return projectData
    } catch (error) {
      console.error('Error fetching project data:', error)
      return []
    }
  },
})

export const navbarDataState = atom<ExecutionModel | null>({
  key: 'navbarDataState',
  default: null,
})

export const navbarProjectDataSelector = selector({
  key: 'navbarProjectDataSelector',
  get: async () => {
    try {
      const projectData = await fetchAllProjectData()
      return projectData
    } catch (error) {
      console.error('Error fetching project data:', error)
      return []
    }
  },
})

export const forecastDataSelector = selector({
  key: 'forecastSelector',
  get: async ({ get }) => {
    const currentPage = get(forecastcurrentPageState)
    const pageSize = get(pageSizeState)
    const getSelectedProjectId = get(selectedProjectIdState)
    const nameFilter = get(nameFilterState)
    const yearsFilter = get(yearsFilterState)
    const periodFilter = get(periodFilterState)
    const createdDateFilter = get(createdDateFilterState)
    if (getSelectedProjectId !== 0) {
      try {
        const forecastData = await fetchForecastingData(
          getSelectedProjectId,
          currentPage,
          pageSize,
          nameFilter,
          yearsFilter,
          periodFilter,
          createdDateFilter,
        )
        return forecastData
      } catch (error) {
        console.error('Error fetching project data:', error)
        return []
      }
    }
    return []
  },
})

export const searchQueryState = atom<string>({
  key: 'searchQueryState',
  default: '',
})

export const projectSortColumnState = atom<string | number>({
  key: 'projectSortColumnState',
  default: 'created_at',
})

export const projectSortOrderState = atom<string>({
  key: 'projectSortOrderState',
  default: 'desc',
})

export const selectedProjectIdState = atom({
  key: 'selectedProjectId',
  default: 0,
})

export const formStepState = atom<number>({
  key: 'formStepState',
  default: 1,
})

export const handleScenariosErrorState = atom<ErrorMessageConfig>({
  key: 'errorMessageState',
  default: ScenarioErrorMessageConfig,
})

export const formDataState = atom<ScenarioFormData>({
  key: 'formDataState',
  default: ScenarioDefaultValues,
})

export const uploadGeneralProfileFormState = atom<UploadGeneralProfileFormStateRequestModel>({
  key: 'uploadGeneralProfileFormState',
  default: {
    description: '',
    interval: '',
    start_date: '',
    period: '',
    name: '',
    profile_type: 2,
    is_leap_year_included: false,
  },
})

export const uploadFormState = atom<UploadForecastRequestModel>({
  key: 'uploadFormState',
  default: {
    name: '',
    description: '',
    interval: '',
    start_date: '',
    file_type: 1,
    period: '',
    profile_type: null,
    is_leap_year_included: false,
  },
})

export const nameFilterState = atom<string[]>({
  key: 'nameFilterState',
  default: [],
})

export const yearsFilterState = atom<string>({
  key: 'yearsFilterState',
  default: '',
})

export const periodFilterState = atom<string>({
  key: 'periodFilterState',
  default: '',
})

export const createdDateFilterState = atom<{ startDate: Date | null; endDate: Date | null }>({
  key: 'createdDateFilterState',
  default: { startDate: null, endDate: null },
})

export const navlinksSelector = atom<NavLink[]>({
  key: 'navlinksSelector',
  default: [],
})

export interface NavLink {
  label: string
  href: string
}

export const forecastDataForFilterSelector = selector({
  key: 'forecastDataForFilterSelector',
  get: async ({ get }) => {
    const currentPage = 1
    const pageSize = 50
    const getSelectedProjectId = get(selectedProjectIdState)
    if (getSelectedProjectId !== 0) {
      try {
        const forecastData = await fetchForecastingDataForFilter(getSelectedProjectId, currentPage, pageSize)
        return forecastData
      } catch (error) {
        console.error('Error fetching project data:', error)
        return []
      }
    }
    return []
  },
})

export const scenerioProjectIdState = atom({
  key: 'scenerioProjectId',
  default: 0,
})

export const getAllForecastData = selector({
  key: 'getAllForecastData',
  get: async ({ get }) => {
    const getSelectedProjectId = get(scenerioProjectIdState)
    if (getSelectedProjectId !== 0) {
      try {
        const forecastData = await fetchallForecastDetails(getSelectedProjectId)
        return forecastData
      } catch (error) {
        console.error('Error fetching project data:', error)
        return []
      }
    }
    return []
  },
})

export const scenarioCurrentPageState = atom<number>({
  key: 'scenarioCurrentPageState',
  default: 1,
})

export const scenariopageSizeState = atom<number>({
  key: 'scenariopageSizeState',
  default: 20,
})

export const scenarionameFilterState = atom<string[]>({
  key: 'scenarionameFilterState',
  default: [],
})

export const scenarioyearsFilterState = atom<string>({
  key: 'scenarioyearsFilterState',
  default: '',
})

export const scenariocreatedDateFilterState = atom<{ startDate: Date | null; endDate: Date | null }>({
  key: 'scenariocreatedDateFilterState',
  default: { startDate: null, endDate: null },
})

export const scenarioListSelector = selector({
  key: 'scenarioListSelector',
  get: async ({ get }) => {
    const currentPage = get(scenarioCurrentPageState)
    const pageSize = get(scenariopageSizeState)
    const getSelectedProjectId = get(selectedProjectIdState)
    const nameFilter = get(scenarionameFilterState)
    const yearsFilter = get(scenarioyearsFilterState)
    const createdDateFilter = get(scenariocreatedDateFilterState)
    if (getSelectedProjectId !== 0) {
      try {
        const forecastData = await fetchScenarioData(
          getSelectedProjectId,
          currentPage,
          pageSize,
          nameFilter,
          yearsFilter,
          createdDateFilter,
        )
        return forecastData
      } catch (error) {
        console.error('Error fetching project data:', error)
        return []
      }
    }
    return []
  },
})
export const allScenarioListSelector = selector({
  key: 'allScenarioListSelector',
  get: async ({ get }) => {
    const getSelectedProjectId = get(selectedProjectIdState)
    if (getSelectedProjectId !== 0) {
      try {
        const forecastData = await fetchAllScenarioData(getSelectedProjectId)
        return forecastData
      } catch (error) {
        console.error('Error fetching project data:', error)
        return []
      }
    }
    return []
  },
})

/**
 * Execution Stores recoil variables
 */

export const executionSearchQueryState = atom<string>({
  key: 'executionSearchQueryState',
  default: '',
})

export const executionDataState = atom<ExecutionModel | null>({
  key: 'executionDataState',
  default: null,
})

export const executionCurrentPageState = atom<number>({
  key: 'executionCurrentPageState',
  default: 1,
})

export const executionpageSizeState = atom<number>({
  key: 'executionpageSizeState',
  default: 20,
})

export const selectedMenuItemState = atom<string>({
  key: 'selectedMenuItemState',
  default: '',
})

export const fetchExecutionDataSelector = selector({
  key: 'fetchExecutionDataSelector',
  get: async ({ get }) => {
    const executionsearchquery = get(executionSearchQueryState)
    const getSelectedProjectId = get(selectedProjectIdState)
    const currentPage = get(executionCurrentPageState)
    const pageSize = get(executionpageSizeState)
    if (getSelectedProjectId !== 0) {
      try {
        const response = await fetchExecutionsList(getSelectedProjectId, currentPage, pageSize, executionsearchquery)
        const executionData: ExecutionResponse = {
          items: response['items'],
          total: response['total'],
          page: response['page'],
          size: response['size'],
          pages: response['pages'],
        }
        return executionData ? ExecutionModel.fromJSON(executionData) : null
      } catch (error) {
        console.error('Error fetching execution data:', error)
        return null
      }
    }
    return null
  },
  set: ({ set }, newValue) => {
    set(executionDataState, newValue)
  },
})

/**
 * Reports Stores recoil variables
 */

export const reportsSearchQueryState = atom<string>({
  key: 'reportsSearchQueryState',
  default: '',
})

export const reportsDataState = atom<ReportsModel | null>({
  key: 'reportsDataState',
  default: null,
})

export const reportsCurrentPageState = atom<number>({
  key: 'reportsCurrentPageState',
  default: 1,
})

export const reportspageSizeState = atom<number>({
  key: 'reportspageSizeState',
  default: 20,
})

export const fetchReportsDataSelector = selector({
  key: 'fetchReportsDataSelector',
  get: async ({ get }) => {
    const reportsSearchquery = get(reportsSearchQueryState)
    const getSelectedProjectId = get(selectedProjectIdState)
    const currentPage = get(reportsCurrentPageState)
    const pageSize = get(reportspageSizeState)
    if (getSelectedProjectId !== 0) {
      try {
        const response = await fetchReportsList(getSelectedProjectId, currentPage, pageSize, reportsSearchquery)
        const reportsData: ReportsResponse = {
          items: response['items'],
          total: response['total'],
          page: response['page'],
          size: response['size'],
          pages: response['pages'],
        }
        return reportsData ? ReportsModel.fromJSON(reportsData) : null
      } catch (error) {
        console.error('Error fetching execution data:', error)
        return null
      }
    }
    return null
  },
  set: ({ set }, newValue) => {
    set(reportsDataState, newValue)
  },
})

export const reportSelectScenarioFormState = atom<FetchReportMappingDataRequestModel>({
  key: 'reportSelectScenarioFormState',
  default: {
    name: '',
    execution_scenario_association_id: null,
    preferred_date: '',
    startDate: '',
    endDate: '',
    clone_report_id: 0,
  },
})

export const dashboardsearchQueryState = atom<string>({
  key: 'dashboardsearchQueryState',
  default: '',
})

export const dashboardCurrentPage = atom<number>({
  key: 'dashboardCurrentPage',
  default: 1,
})

export const dashboardSortColumnState = atom<string | number>({
  key: 'dashboardSortColumnState',
  default: 'name',
})

export const dashboardSortOrderState = atom<string>({
  key: 'dashboardSortOrderState',
  default: 'desc',
})

export const dashboadKpiDataSelector = selector({
  key: 'dashboadKpiDataSelector',
  get: async ({ get }) => {
    const getSelectedProjectId = get(selectedProjectIdState)
    if (getSelectedProjectId !== 0) {
      try {
        const kpiData = await getdashboardkpi(getSelectedProjectId)
        return kpiData
      } catch (error) {
        console.error('Error fetching kpi data:', error)
        return []
      }
    }
    return []
  },
})

export const dashboardOverallSummary = selector({
  key: 'dashboardOverallSummary',
  get: async ({ get }) => {
    const getSelectedProjectId = get(selectedProjectIdState)
    const currentPage = get(dashboardCurrentPage)
    const pageSize = get(pageSizeState)
    const searchquery = get(dashboardsearchQueryState)
    const sortColumn = get(dashboardSortColumnState)
    const sortOrder = get(dashboardSortOrderState)
    if (getSelectedProjectId !== 0) {
      try {
        const response = await getOverallSummary(
          getSelectedProjectId,
          currentPage,
          pageSize,
          sortColumn,
          sortOrder,
          searchquery,
        )
        return response
      } catch (error) {
        console.error('Error fetching overall Summary data:', error)
        return []
      }
    }
    return []
  },
})

export const dashboardNvbbreakdown = selector({
  key: 'dashboardNvbbreakdown',
  get: async ({ get }) => {
    const getSelectedProjectId = get(selectedProjectIdState)
    const currentPage = get(dashboardCurrentPage)
    const pageSize = get(pageSizeState)
    const searchquery = get(dashboardsearchQueryState)
    const sortColumn = get(dashboardSortColumnState)
    const sortOrder = get(dashboardSortOrderState)
    if (getSelectedProjectId !== 0) {
      try {
        const response = await getnpv(getSelectedProjectId, currentPage, pageSize, sortColumn, sortOrder, searchquery)
        return response
      } catch (error) {
        console.error('Error fetching NPVbreakdown data:', error)
        return []
      }
    }
    return []
  },
})

export const dashboardExecutionList = selector({
  key: 'dashboardExecutionList',
  get: async ({ get }) => {
    const getSelectedProjectId = get(selectedProjectIdState)
    if (getSelectedProjectId !== 0) {
      try {
        const response = await getallExecutionData(getSelectedProjectId)
        return response
      } catch (error) {
        console.error('Error fetching ExecutionList data:', error)
        return []
      }
    }
    return []
  },
})

export const dashboardScenerioId = atom<number>({
  key: 'dashboardScenerioId',
  default: 0,
})

export const dashboardExecutionChart = selector({
  key: 'dashboardExecutionChart',
  get: async ({ get }) => {
    const getSelectedProjectId = get(dashboardScenerioId)
    if (getSelectedProjectId !== 0) {
      try {
        const response = await getEnergy(getSelectedProjectId)
        return response
      } catch (error) {
        console.error('Error fetching ExecutionList data:', error)
        return []
      }
    }
    return []
  },
})
export const inProductedSecretState = atom<ProductionSecretModel | null>({
  key: 'inProductedSecretState',
  default: null,
})

export const inProductedSecret = selector({
  key: 'inProductedSecret',
  get: async ({ get }) => {
    try {
      const response = await fetchProductedSecret()
      console.log('responseData', get)
      const responseData = {
        data: response,
      }
      return responseData
    } catch (error) {
      console.error('Error fetching ScenerioList data:', error)
      return []
    }
  },
})

export const userCurrentPage = atom<number>({
  key: 'userCurrentPage',
  default: 1,
})

export const allUserList = selector({
  key: 'allUserList',
  get: async ({ get }) => {
    try {
      const response = await fetchAllUser()
      console.log('responseData', get)
      const responseData = {
        data: response,
      }
      return responseData
    } catch (error) {
      console.error('Error fetching fetchAllUser data:', error)
      return []
    }
  },
})

export const getallForecastWithGenerationFileTypeDetails = selector({
  key: 'getallForecastWithGenerationFileTypeDetails',
  get: async ({ get }) => {
    const getSelectedProjectId = get(scenerioProjectIdState)
    if (getSelectedProjectId !== 0) {
      try {
        const forecastData = await fetchallForecastWithFileTypeDetails(getSelectedProjectId, 2, 4)
        return forecastData
      } catch (error) {
        console.error('Error fetching project data:', error)
        return []
      }
    }
    return []
  },
})

export const getallForecastWithFileTypeDetails = selector({
  key: 'getallForecastWithFileTypeDetails',
  get: async ({ get }) => {
    const getSelectedProjectId = get(scenerioProjectIdState)
    if (getSelectedProjectId !== 0) {
      try {
        const forecastData = await fetchallForecastWithFileTypeDetails(getSelectedProjectId, 1, 4)
        return forecastData
      } catch (error) {
        console.error('Error fetching project data:', error)
        return []
      }
    }
    return []
  },
})

export const getSwaggerJson = selector({
  key: 'swaggerDoc',
  get: async ({ get }) => {
    try {
      const response = await fetchSwaggerDoc()
      console.log('responseData', get)
      return response.data
    } catch (error) {
      console.error('Error fetching fetchSwaggerDoc data:', error)
      return []
    }
  },
})
