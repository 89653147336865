import { getAsync, getAsyncList, postAsync, putAsync } from 'src/helpers/apiRequest'
import { BessInputsInterface, CommercialParametersInterface, ScenarioFormData } from 'src/models/ScenarioFormData'
import {
  CommercialParameter,
  CreateUpdateScenarioRequestModelInterface,
} from 'src/models/api/CreateScenarioRequestModel'
import { ForecastModalRequestModal } from 'src/models/api/ForecastModalRequestModal'
import { ForecastResponseModel } from 'src/models/api/ForecastModalResponse'
import { IBodyRequestModel } from 'src/models/api/IBodyRequestModel'
import { getToken } from './TokenManager'
import { EditScenarioResponseModelInterface } from 'src/models/api/EditScenarioResponseModel'
import { getCurrentDateISOString } from 'src/helpers/utils'

const fetchallForecastDetails = async (project_id: number) => {
  try {
    const token = await getToken()
    const requestbody: IBodyRequestModel = {
      token: token,
    }
    const baseUri = `/v1/forecast-data/all`
    const response = await getAsyncList<ForecastModalRequestModal, ForecastResponseModel>(
      `${baseUri}?project_id=${project_id}`,
      requestbody,
    )
    return response
  } catch (error) {
    throw new Error(`Error fetching data: ${error}`)
  }
}

const fetchallForecastWithFileTypeDetails = async (project_id: number, formType: number, status: number) => {
  try {
    const token = await getToken()
    const requestbody: IBodyRequestModel = {
      token: token,
    }
    const baseUri = `/v1/forecast-data/all`
    const response = await getAsync<ForecastModalRequestModal, ForecastResponseModel>(
      `${baseUri}?project_id=${project_id}&file_type=${formType}&status_id=${status}`,
      requestbody,
    )
    return response
  } catch (error) {
    throw new Error(`Error fetching data: ${error}`)
  }
}

const fetchScenarioDetails = async (scenarioId: string) => {
  try {
    const token = await getToken()
    const requestbody: IBodyRequestModel = {
      token: token,
    }
    const baseUri = `/v1/scenarios/${scenarioId}`
    const response = await getAsync<IBodyRequestModel, EditScenarioResponseModelInterface>(baseUri, requestbody)
    return response
  } catch (error) {
    throw new Error(`Error fetching data: ${error}`)
  }
}

type ConvertibleToString = string | number | boolean | null | undefined

function isConvertibleToString(value: unknown): value is ConvertibleToString {
  return ['string', 'number', 'boolean'].includes(typeof value) || value === null || value === undefined
}

type Convertible<T> = {
  [K in keyof T]: ConvertibleToString
}

const convertKeysToString = <T>(array: T[], keysToConvert: (keyof T)[]): Convertible<T>[] => {
  return array.map((obj) => {
    const newObj = { ...obj } as Convertible<T>
    keysToConvert.forEach((key) => {
      if (key in newObj && isConvertibleToString(newObj[key])) {
        newObj[key] = (newObj[key] + '%') as ConvertibleToString
      }
    })
    return newObj
  })
}

export const convertKeysToRemovePercentageInString = <T>(
  keysToConvert: (keyof T)[],
  array: T[] = [],
): Convertible<T>[] => {
  if (!Array.isArray(array)) return []
  return array.map((obj) => {
    const newObj = { ...obj } as Convertible<T>
    keysToConvert.forEach((key) => {
      if (key in newObj && isConvertibleToString(newObj[key])) {
        if (newObj[key] !== null && newObj[key] !== undefined) {
          const valueWithoutPercentage = (newObj[key] as string).replace('%', '')
          newObj[key] = valueWithoutPercentage as ConvertibleToString
        }
      }
    })
    return newObj
  })
}

const convertValueToIntegervalue = (value: string | undefined) => {
  if (value && typeof value === 'string' && value.includes('%')) {
    const numericValue = value.replace('%', '')
    const numberValue = parseFloat(numericValue)
    return Math.round(numberValue * 100)
  }
  if (value) {
    const numberValue = parseFloat(value)
    return Math.round(numberValue * 100)
  }
  return value ?? 0
}

export const convertKeysToStringData = <T>(array: T[], keysToConvert: (keyof T)[]): Convertible<T>[] => {
  return array.map((obj) => {
    const newObj = { ...obj } as Convertible<T>
    keysToConvert.forEach((key) => {
      if (key in newObj && isConvertibleToString(newObj[key])) {
        newObj[key] = (convertValueToIntegervalue(newObj[key] as string) as ConvertibleToString) ?? ''
      }
    })
    return newObj
  })
}

export const createUpdateScenarioApi = async (apitype: string, scenarioId: string, ScenarioData: ScenarioFormData) => {
  try {
    const token = await getToken()

    const baseUri = `/v1/scenarios`

    const bessInputsKeys: (keyof BessInputsInterface)[] = [
      'energy_discharge_capacity',
      'rte',
      'correction_factor_half_cycle',
      'correction_factor_one_cycle',
      'correction_factor_one_and_half_cycle',
      'correction_factor_two_cycle',
    ]
    const commercialParametersKeys: (keyof CommercialParametersInterface)[] = [
      'long_term_service_agreement_cost_percentage',
      'warranty_cost_percentage',
    ]
    const newBessInputs = convertKeysToString(ScenarioData.bess_inputs, bessInputsKeys)
    const newCommercialParameters = convertKeysToString(ScenarioData.commercial_parameters, commercialParametersKeys)
    const payload: CreateUpdateScenarioRequestModelInterface = {
      token: token,
      created_at: getCurrentDateISOString(),
      updated_at: getCurrentDateISOString(),
      created_by: getCurrentDateISOString(),
      updated_by: getCurrentDateISOString(),
      name: ScenarioData.name,
      description: ScenarioData.description,
      project_id: ScenarioData.projectID,
      forecast_data_id: Number(ScenarioData.forecast_data_id),
      generating_profile_id: Number(ScenarioData.generating_profile_id)
        ? Number(ScenarioData.generating_profile_id)
        : null,
      scenario_detail: {
        created_at: getCurrentDateISOString(),
        updated_at: getCurrentDateISOString(),
        created_by: getCurrentDateISOString(),
        updated_by: getCurrentDateISOString(),
        project_title: ScenarioData.project_title,
        year_of_deployment: Number(ScenarioData.year_of_deployment),
        project_life_years: Number(ScenarioData.project_life_years),
        first_year_of_commercial_operation: Number(ScenarioData.first_year_of_commercial_operation),
        rated_discharge_power: Number(ScenarioData.rated_discharge_power),
        rated_charge_power: Number(ScenarioData.rated_charge_power),
        bol_storage_capacity: ScenarioData.bol_storage_capacity,
        vcas_rates_mvar: ScenarioData.vcas_rates_mvar,
        overload_discharge_power: ScenarioData.overload_discharge_power,
        overload_charge_power: ScenarioData.overload_charge_power,
        inverter_capacity: ScenarioData.inverter_capacity,
        is_cap_contract_intended: ScenarioData.Cap_contracting_is_intended,
        is_manual_ltsa_input: ScenarioData.is_manual_ltsa_input,
        is_manual_warranty_input: ScenarioData.is_manual_warranty_input,
        is_network_services_intended: ScenarioData.network_support_services_intended,
        is_inertia_contract:
          ScenarioData.Inertia_contract !== null && ScenarioData.Inertia_contract !== undefined
            ? !!ScenarioData.Inertia_contract
            : false,
        is_sips_contract: !!ScenarioData.is_sips_contract,
        is_sras_contract: !!ScenarioData.is_sras_contract,
        is_vcas_contract: !!ScenarioData.is_vcas_contract,
        inertia_contract: ScenarioData.Inertia_contract ? 1 : 0,
        selected_inertia_constant: ScenarioData.selected_inertia_constant ? 1 : 0,
        rocof_design_criteria: ScenarioData.rocof_design_criteria ? 1 : 0,
        standby_power_consumption: ScenarioData.standby_power_consumption ? 1 : 0,
        standby_power_consumption_unit: ScenarioData.standby_power_consumption_unit,
        default_warrant_cycle: ScenarioData.default_warrant_cycle ? 1 : 0,
        o_and_m_inflation_rate: ScenarioData.o_and_m_inflation_rate.toString() + '%',
        pop_wholesale_energy_generation: ScenarioData.pop_wholesale_energy_generation?.toString() + '%',
        pop_wholesale_energy_load: ScenarioData.pop_wholesale_energy_load?.toString() + '%',
        pop_reg_fcas: ScenarioData.pop_reg_fcas?.toString() + '%',
        pop_contingency_fcas: ScenarioData.pop_contingency_fcas?.toString() + '%',
        standard_inflation_rate: ScenarioData.standard_inflation_rate.toString() + '%',
        total_project_cost_uplift: ScenarioData.total_project_cost_uplift.toString() + '%',
        npv_discount_rate: ScenarioData.npv_discount_rate.toString() + '%',
        ltsa_avg_cost: 0,
        market_inputs: {
          created_at: getCurrentDateISOString(),
          updated_at: getCurrentDateISOString(),
          created_by: getCurrentDateISOString(),
          updated_by: getCurrentDateISOString(),
          is_client_nominated_mef_avail: !!ScenarioData.is_client_nominated_mef_avail,
          mef_for_each_market: false,
          r1_max_registered_capacity: ScenarioData.r1_max_registered_capacity?.toString() + '%',
          r5_max_registered_capacity: ScenarioData.r5_max_registered_capacity?.toString() + '%',
          r6_max_registered_capacity: ScenarioData.r6_max_registered_capacity?.toString() + '%',
          r60_max_registered_capacity: ScenarioData.r60_max_registered_capacity?.toString() + '%',
          l1_max_registered_capacity: ScenarioData.l1_max_registered_capacity?.toString() + '%',
          l5_max_registered_capacity: ScenarioData.l5_max_registered_capacity?.toString() + '%',
          l6_max_registered_capacity: ScenarioData.l6_max_registered_capacity?.toString() + '%',
          l60_max_registered_capacity: ScenarioData.l60_max_registered_capacity?.toString() + '%',
          reg_raise_max_enablement: Number(ScenarioData.reg_raise_max_enablement),
          reg_lower_max_enablement: Number(ScenarioData.reg_lower_max_enablement),
          r1_max_enablement: Number(ScenarioData.r1_max_enablement),
          r5_max_enablement: Number(ScenarioData.r5_max_enablement),
          r6_max_enablement: Number(ScenarioData.r6_max_enablement),
          r60_max_enablement: Number(ScenarioData.r60_max_enablement),
          l1_max_enablement: Number(ScenarioData.l1_max_enablement),
          l5_max_enablement: Number(ScenarioData.l5_max_enablement),
          l6_max_enablement: Number(ScenarioData.l6_max_enablement),
          l60_max_enablement: Number(ScenarioData.l60_max_enablement),
        },
        marginal_loss_factors: ScenarioData.loss_factors,
        cap_contracts: ScenarioData.cap_contracts,
        network_support_services: ScenarioData.network_support_services,
        bess_inputs: newBessInputs as BessInputsInterface[],
        commercial_parameters: newCommercialParameters as CommercialParameter[],
        repex: ScenarioData.repex,
        capex: ScenarioData.capex,

        solve_interval_hours: ScenarioData.solve_interval_hours,
        window_hours: ScenarioData.window_hours,
        time_res: Number(ScenarioData.time_res),
        rreg_utilisation: ScenarioData.rreg_utilisation,
        lreg_utilisation: ScenarioData.lreg_utilisation,
        inertia_utilisation: ScenarioData.inertia_utilisation,
        is_non_market_service: ScenarioData.is_non_market_service,

        scenario_project_type: ScenarioData.scenario_project_type,
        hybrid_generation_type: Number(ScenarioData.hybrid_generation_type)
          ? Number(ScenarioData.hybrid_generation_type)
          : null,
        coupled_type: ScenarioData.coupled_type ? Number(ScenarioData.coupled_type) : null,
        max_energy_output_to_grid: Number(ScenarioData.max_energy_output_to_grid),
        max_energy_input_from_grid: Number(ScenarioData.max_energy_input_from_grid),
        max_bess_inverter_capacity_in: Number(ScenarioData.max_bess_inverter_capacity_in),
        max_bess_inverter_capacity_out: Number(ScenarioData.max_bess_inverter_capacity_out),
        max_vre_inverter_capacity_out: Number(ScenarioData.max_vre_inverter_capacity_out),
        generation_efficieny: Number(ScenarioData.generation_efficieny),
        ac_charging_efficieny: Number(ScenarioData.ac_charging_efficieny),
        dc_charging_efficieny: Number(ScenarioData.dc_charging_efficieny),
        transfer_efficieny: Number(ScenarioData.transfer_efficieny),
      },
    }

    const response =
      apitype === 'create' ? await postAsync(baseUri, payload) : await putAsync(`${baseUri}/${scenarioId}`, payload)
    return response
  } catch (error) {
    console.error('Error Create Scenario:', error)
    throw error
  }
}

export { fetchallForecastDetails, fetchScenarioDetails, fetchallForecastWithFileTypeDetails }
