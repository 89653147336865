import React, { useCallback, useState, FC } from 'react'
import { Button, Container, useToast } from '@aurecon-creative-technologies/styleguide'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { useRecoilRefresher_UNSTABLE, useRecoilState, useRecoilValue, useRecoilValueLoadable } from 'recoil'
import { appInsights } from 'src/api/AppInsights'
import {
  convertKeysToRemovePercentageInString,
  createUpdateScenarioApi,
  fetchScenarioDetails,
} from 'src/api/ScenarioServices'
import {
  formDataState,
  formStepState,
  inProductedSecretState,
  projectDataSelector,
  scenarioListSelector,
  selectedProjectIdState,
} from 'src/stores/AppStore'
import Style from '../styles/CreateScenario.module.sass'
import LoadingScreen from 'src/components/LoadingScreen'
import { validateStepData } from './scenario/ScenarioValidationSchemas'
import { ScenarioDefaultValues } from 'src/models/ScenarioDefaultValues'
import { ScenarioStepLine } from './scenario/ScenarioStepLine'
import { getCurrentDateISOString } from 'src/helpers/utils'
import {
  BessInputsInterface,
  CapContractsInterface,
  CapxValuesInterface,
  CommercialParametersInterface,
} from 'src/models/ScenarioFormData'
import { Repex } from './../models/api/EditScenarioResponseModel'
import { MarginalLossFactor } from 'src/models/api/CreateScenarioRequestModel'
import { isRoleEmpty } from 'src/helpers/appRoles'

type RemoveKeys<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>

export const removeIdAndIsActive = <T, K extends keyof T>(input: T, keysToRemove: K[]): RemoveKeys<T, K> => {
  const { ...rest } = input
  keysToRemove.forEach((key) => {
    delete rest[key]
  })
  return rest as RemoveKeys<T, K>
}

const EditScenario: FC = () => {
  if (appInsights) appInsights.trackPageView({ name: 'Edit Scenario' })
  const navigate = useNavigate()
  const successEditToast = 'Scenario updated successfully'
  const errorEditToast = 'Failed to edit Scenario'
  const showEditToast = useToast()
  const SecretState = useRecoilValue(inProductedSecretState)
  const [selectedProjectId] = useRecoilState(selectedProjectIdState)
  const [editStep, setEditStep] = useRecoilState(formStepState)
  const [loading, setLoading] = useState(false)
  const [formData, setFormData] = useRecoilState(formDataState)
  const refreshFetchItems = useRecoilRefresher_UNSTABLE(scenarioListSelector)
  const [errors, setErrors] = useState<Record<string, string>>({})
  const [loadingText, setLoadingText] = useState('')
  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)
  const stepParam = queryParams.get('step')
  const viewStep = stepParam ? parseInt(stepParam, 10) : null
  const projectData = useRecoilValueLoadable(projectDataSelector)

  const projectType =
    projectData &&
    projectData.contents['items'] !== undefined &&
    projectData.contents['items'].filter(
      (item: { id: number; project_type: number }) => item.id === selectedProjectId,
    )[0]?.project_type

  const editHandleGoBack = () => {
    setFormData(ScenarioDefaultValues)
    navigate(`/dashboard/${selectedProjectId}/scenario`)
  }

  const addEditScenarioCapexInput = useCallback(
    (year_of_deployment: number, first_year_of_commercial_operation: number) => {
      const deploymentYear = Number(year_of_deployment)
      const yearOfCommercialOperation = Number(first_year_of_commercial_operation)
      const numberOfObjectsToAdd = yearOfCommercialOperation - deploymentYear

      if (!isNaN(numberOfObjectsToAdd)) {
        const updatedEditCapexData: CapxValuesInterface[] = []

        for (let i = 0; i < numberOfObjectsToAdd; i++) {
          const newYearDisplay = deploymentYear + i
          if (newYearDisplay < yearOfCommercialOperation) {
            const newObj: CapxValuesInterface = {
              created_at: getCurrentDateISOString(),
              updated_at: getCurrentDateISOString(),
              created_by: getCurrentDateISOString(),
              updated_by: getCurrentDateISOString(),
              operating_year: i,
              year: newYearDisplay,
              capex_estimate: 0,
              comments: '',
              bess_estimate: 0,
              re_generator_estimate: 0,
              common_equipment: 0,
            }
            updatedEditCapexData.push(newObj)
          }
        }

        setFormData((prevData) => ({
          ...prevData,
          capex: updatedEditCapexData,
        }))
      }
    },
    [setFormData],
  )

  const { scenarioId = '' } = useParams<{ projectId?: string; scenarioId: string }>()
  const nextStep = async () => {
    const stepErrors = await validateStepData(editStep, formData)

    if (!stepErrors) {
      setErrors({})
      if (editStep === 1) {
        addEditScenarioCapexInput(
          Number(formData.year_of_deployment),
          Number(formData.first_year_of_commercial_operation),
        )
      }
      setEditStep((prevStep) => Math.min(prevStep + 1, 7))
    } else {
      setErrors(stepErrors)
    }
  }

  const prevStep = () => setEditStep((prevStep) => Math.max(prevStep - 1, 1))

  const editHandleSubmit = async (event: React.FormEvent) => {
    event.preventDefault()
    setLoading(true)
    setLoadingText('Saving data')
    try {
      const response = await createUpdateScenarioApi('edit', scenarioId, { ...formData, projectID: selectedProjectId })
      if (response.success || response.status === 200 || response.status === 201) {
        editHandleResponseToast(successEditToast, 'success', 3000)
        setFormData(ScenarioDefaultValues)
        setLoading(false)
        setLoadingText('')
        setEditStep(1)
        refreshFetchItems()
        navigate(`/dashboard/${selectedProjectId}/scenario`)
      } else if (response.message === 'Internal Server Error' || response.status === 500) {
        editHandleResponseToast(errorEditToast + '\n' + response.message, 'error', 3000)
      } else {
        editHandleResponseToast(errorEditToast + '\n' + response.message, 'error', 3000)
      }
    } catch (error) {
      editHandleResponseToast(errorEditToast, 'error', 3000)
      console.log(error)
    } finally {
      setLoading(false)
      setLoadingText('')
    }
  }

  const editHandleResponseToast = (
    message: string,
    toastType: 'success' | 'error' | 'info' | 'warning',
    timeout: number,
  ) => {
    showEditToast.addToast({
      message: message,
      type: toastType,
      timeout: timeout,
    })
  }

  const convertValueTostring = (value: string) => {
    if (typeof value === 'string' && value.includes('%')) {
      const numericValue = value.replace('%', '')
      return numericValue
    }

    const numberValue = value.replace('%', '')
    return numberValue
  }

  const removePercentage = (value?: string) => {
    if (typeof value === 'string' && value.includes('%')) {
      const numericValue = value.replace('%', '')
      return String(numericValue)
    }
    return String(value ?? '')
  }

  const editSteps = ['Step 1', 'Step 2', 'Step 3', 'Step 4', 'Step 5', 'Step 6', 'Step 7']

  const fetchAndUpdateFormData = useCallback(async () => {
    setFormData(ScenarioDefaultValues)
    setLoading(true)
    setLoadingText('Fetching data')
    try {
      const responseData = await fetchScenarioDetails(scenarioId)
      if (responseData) {
        const bessInputsKeys: (keyof BessInputsInterface)[] = [
          'energy_discharge_capacity',
          'rte',
          'correction_factor_half_cycle',
          'correction_factor_one_cycle',
          'correction_factor_one_and_half_cycle',
          'correction_factor_two_cycle',
        ]
        const commercialParametersKeys: (keyof CommercialParametersInterface)[] = [
          'long_term_service_agreement_cost_percentage',
          'warranty_cost_percentage',
        ]
        const scenorioData = responseData['scenario_detail']

        const newBessInputs = convertKeysToRemovePercentageInString(bessInputsKeys, scenorioData.bess_inputs)

        const newCommercialParameters = convertKeysToRemovePercentageInString(
          commercialParametersKeys,
          scenorioData.commercial_parameters,
        )

        setFormData({
          projectID: responseData['project_id'],
          name: responseData['name'],
          forecast_data_id: responseData['forecast_data_id'] ? String(responseData['forecast_data_id']) : '',
          generating_profile_id: responseData['generating_profile_id']
            ? String(responseData['generating_profile_id'])
            : '',
          description: responseData['description'],
          project_title: '',
          project_life_years: scenorioData.project_life_years,
          year_of_deployment: scenorioData.year_of_deployment,
          first_year_of_commercial_operation: scenorioData.first_year_of_commercial_operation,
          rated_discharge_power: scenorioData.rated_discharge_power,
          rated_discharge_power_unit: 'MW',
          rated_charge_power: scenorioData.rated_charge_power,
          rated_charge_power_unit: 'MW',
          bol_storage_capacity: scenorioData.bol_storage_capacity,
          BOL_storage_capacity_unit: 'MW',
          vcas_rates_mvar: scenorioData.vcas_rates_mvar,
          VCAS_rates_MVAr_unit: 'MVAr',
          overload_discharge_power: scenorioData.overload_discharge_power,
          Overload_discharge_power_unit: 'MW',
          overload_charge_power: scenorioData.overload_charge_power,
          Overload_charge_Power_unit: 'MW',
          inverter_capacity: scenorioData.inverter_capacity,
          inverter_capacity_unit: 'MVA',
          is_client_nominated_mef_avail: scenorioData.market_inputs[0].is_client_nominated_mef_avail,
          r1_max_registered_capacity: convertValueTostring(scenorioData.market_inputs[0].r1_max_registered_capacity),
          r6_max_registered_capacity: convertValueTostring(scenorioData.market_inputs[0].r6_max_registered_capacity),
          r60_max_registered_capacity: convertValueTostring(scenorioData.market_inputs[0].r60_max_registered_capacity),
          r5_max_registered_capacity: convertValueTostring(scenorioData.market_inputs[0].r5_max_registered_capacity),
          l1_max_registered_capacity: convertValueTostring(scenorioData.market_inputs[0].l1_max_registered_capacity),
          l6_max_registered_capacity: convertValueTostring(scenorioData.market_inputs[0].l6_max_registered_capacity),
          l60_max_registered_capacity: convertValueTostring(scenorioData.market_inputs[0].l60_max_registered_capacity),
          l5_max_registered_capacity: convertValueTostring(scenorioData.market_inputs[0].l5_max_registered_capacity),
          reg_raise_max_enablement: scenorioData.market_inputs[0].reg_raise_max_enablement,
          reg_lower_max_enablement: scenorioData.market_inputs[0].reg_lower_max_enablement,
          r1_max_enablement: scenorioData.market_inputs[0].r1_max_enablement,
          r5_max_enablement: scenorioData.market_inputs[0].r5_max_enablement,
          r6_max_enablement: scenorioData.market_inputs[0].r6_max_enablement,
          r60_max_enablement: scenorioData.market_inputs[0].r60_max_enablement,
          l1_max_enablement: scenorioData.market_inputs[0].l1_max_enablement,
          l5_max_enablement: scenorioData.market_inputs[0].l5_max_enablement,
          l6_max_enablement: scenorioData.market_inputs[0].l6_max_enablement,
          l60_max_enablement: scenorioData.market_inputs[0].l60_max_enablement,
          loss_factors: scenorioData.marginal_loss_factors.map((item: MarginalLossFactor) => {
            delete item.id
            delete item.is_active
            return item
          }) as MarginalLossFactor[],
          Cap_contracting_is_intended: scenorioData.is_cap_contract_intended,
          cap_contracts: scenorioData.cap_contracts.map((item: CapContractsInterface) => {
            delete item.id
            delete item.is_active
            return item
          }) as CapContractsInterface[],
          network_support_services_intended: scenorioData.is_network_services_intended,
          Inertia_contract: scenorioData.is_inertia_contract,
          selected_inertia_constant: scenorioData.selected_inertia_constant,
          rocof_design_criteria: scenorioData.rocof_design_criteria,
          network_support_services: scenorioData.network_support_services,
          is_sips_contract: scenorioData.is_sips_contract,
          is_sras_contract: scenorioData.is_sras_contract,
          is_vcas_contract: scenorioData.is_vcas_contract,
          is_non_market_service: false,
          standby_power_consumption: scenorioData.standby_power_consumption,
          standby_power_consumption_unit: scenorioData.standby_power_consumption_unit,
          default_warrant_cycle: scenorioData.default_warrant_cycle,
          Default_warranted_cycling_per_day: '1',
          bess_inputs: newBessInputs.map((item) => {
            delete item.id
            delete item.is_active
            return item
          }) as BessInputsInterface[],
          repex: scenorioData.repex.map((item: Repex) => {
            delete item.id
            delete item.is_active
            return item
          }) as Repex[],
          capex: scenorioData.capex.map((item: CapxValuesInterface) => {
            delete item.id
            delete item.is_active
            return item
          }) as CapxValuesInterface[],
          is_manual_ltsa_input: scenorioData.is_manual_ltsa_input,
          is_manual_warranty_input: scenorioData.is_manual_warranty_input,
          commercial_parameters: newCommercialParameters.map((item) => {
            delete item.id
            delete item.is_active
            return item
          }) as CommercialParametersInterface[],
          o_and_m_inflation_rate: removePercentage(scenorioData.o_and_m_inflation_rate),
          pop_wholesale_energy_generation: removePercentage(scenorioData.pop_wholesale_energy_generation),
          pop_wholesale_energy_load: removePercentage(scenorioData.pop_wholesale_energy_load),
          pop_reg_fcas: removePercentage(scenorioData.pop_reg_fcas),
          pop_contingency_fcas: removePercentage(scenorioData.pop_contingency_fcas),
          standard_inflation_rate: removePercentage(scenorioData.standard_inflation_rate),
          total_project_cost_uplift: removePercentage(scenorioData.total_project_cost_uplift),
          npv_discount_rate: removePercentage(scenorioData.npv_discount_rate),
          solve_interval_hours: scenorioData.solve_interval_hours,
          window_hours: scenorioData.window_hours,
          time_res: scenorioData.time_res,
          rreg_utilisation: scenorioData.rreg_utilisation,
          lreg_utilisation: scenorioData.lreg_utilisation,
          inertia_utilisation: scenorioData.inertia_utilisation,

          scenario_project_type: scenorioData.scenario_project_type ? scenorioData.scenario_project_type : projectType,
          hybrid_generation_type: scenorioData.hybrid_generation_type
            ? String(scenorioData.hybrid_generation_type)
            : '',
          coupled_type: scenorioData.coupled_type ? String(scenorioData.coupled_type) : '1',

          max_inverter_capacity_out: scenorioData.max_inverter_capacity_,
          max_inverter_capacity_out_unit: 'MW',
          max_inverter_capacity_in: scenorioData.max_inverter_capacity_in,
          max_inverter_capacity_in_unit: 'MW',
          max_bess_inverter_capacity_out: scenorioData.max_bess_inverter_capacity_out,
          max_bess_inverter_capacity_out_unit: 'MW',
          max_bess_inverter_capacity_in: scenorioData.max_bess_inverter_capacity_in,
          max_bess_inverter_capacity_in_unit: 'MW',
          max_vre_inverter_capacity_out: scenorioData.max_vre_inverter_capacity_out,
          max_vre_inverter_capacity_out_unit: 'MW',
          max_energy_output_to_grid: scenorioData.max_energy_output_to_grid,
          max_energy_output_to_grid_unit: 'MW',
          max_energy_input_from_grid: scenorioData.max_energy_input_from_grid,
          max_energy_input_from_grid_unit: 'MW',
          re_generation_nameplate: scenorioData.re_generation_nameplate,
          generation_efficieny: scenorioData.generation_efficieny,
          ac_charging_efficieny: scenorioData.ac_charging_efficieny,
          dc_charging_efficieny: scenorioData.dc_charging_efficieny,
          transfer_efficieny: scenorioData.transfer_efficieny,
        })
      }
    } catch (error) {
      console.error('Error fetching project data:', error)
    } finally {
      setLoading(false)
    }
  }, [projectType, scenarioId, setFormData])

  React.useEffect(() => {
    if (viewStep === 7) {
      setEditStep(viewStep)
      fetchAndUpdateFormData()
    } else {
      setEditStep(1)
      fetchAndUpdateFormData()
    }
  }, [fetchAndUpdateFormData, setEditStep, viewStep])

  return (
    <div>
      {loading ? (
        <LoadingScreen text={loadingText} />
      ) : (
        <>
          <div className={Style.mainBlock}>
            <div className={Style.goBack}>
              <Button type='text' onClick={editHandleGoBack} label='Go back' icon='arrow_back_ios' size='small' />
              <h2>{viewStep === 7 ? 'View' : 'Edit'} Scenario</h2>
            </div>
            {SecretState && !isRoleEmpty(SecretState.role) && viewStep !== 7 && (
              <div className={Style.stepBtn}>
                <Button
                  type='secondary'
                  size='medium'
                  label='Previous'
                  cssClass={Style.btnPrevious}
                  onClick={prevStep}
                  disabled={!loading ? editStep === 1 : loading}
                />
                <Button
                  type='secondary'
                  label={editStep === editSteps.length ? 'Save' : 'Next'}
                  flexible
                  cssClass={Style.btnNext}
                  size='medium'
                  onClick={editStep === editSteps.length ? editHandleSubmit : nextStep}
                  disabled={loading}
                />
              </div>
            )}
          </div>
          <Container fluid cssClass={Style.paddingX}>
            <ScenarioStepLine
              step={editStep}
              steps={editSteps}
              formType={true}
              updateFormData={formData}
              setUpdateFormData={setFormData}
              error={errors}
              hideLines={viewStep !== 7}
            />
          </Container>
        </>
      )}
    </div>
  )
}

export default EditScenario
